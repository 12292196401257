


































import { defineComponent, ref } from "@nuxtjs/composition-api";
import { useUiState } from '~/composables';
import { required } from "vee-validate/dist/rules";
import { ValidationProvider, extend } from "vee-validate";
import type { PropType } from "@nuxtjs/composition-api";
import { SfSelect } from "@storefront-ui/vue";
import type { CustomFormField } from "~/modules/GraphQL/types";
extend("required", {
  ...required,
  message: "This field is required",
});

export default defineComponent({
  name: 'CustomFormFieldSelect',
  components: {
    SfSelect,
    ValidationProvider
  },
  props: {
    field: {
      type: Object as PropType<CustomFormField>,
      default: null,
    },
    formId: {
      type: Number,
      default: null,
    },
    identifier: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { setCustomFormValue } = useUiState();
    const custom_field = ref('');
    props.field.values.map((value, index) => {
      if (typeof value.selected !== 'undefined') {
        custom_field.value = value.value;
      }
    });
    return {
      custom_field,
      setCustomFormValue,
    };
  },
});
