


















































































































































































































import LazyHydrate from "vue-lazy-hydration";
import { SfSelect, SfHeading } from "@storefront-ui/vue";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  ssrRef,
  useFetch,
  useContext,
} from "@nuxtjs/composition-api";
import { CacheTagPrefix, useCache } from "@vue-storefront/cache";
import { usePageStore } from "~/stores/page";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import CategoryPagination from "~/modules/catalog/category/components/pagination/CategoryPagination.vue";
import {
  useCategory,
  useFacet,
  useUiHelpers,
  useUiState,
  useConfig,
} from "~/composables";

import { useAddToCart } from "~/helpers/cart/addToCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { usePrice } from "~/modules/catalog/pricing/usePrice";
import { useCategoryContent } from "~/modules/catalog/category/components/cms/useCategoryContent";
import { useTraverseCategory } from "~/modules/catalog/category/helpers/useTraverseCategory";
import facetGetters from "~/modules/catalog/category/getters/facetGetters";
import { getMetaInfo } from "~/helpers/getMetaInfo";

import CategoryNavbar from "~/modules/catalog/category/components/navbar/CategoryNavbar.vue";
import CategoryBreadcrumbs from "~/modules/catalog/category/components/breadcrumbs/CategoryBreadcrumbs.vue";
import BrandBreadcrumbs from "~/modules/catalog/category/components/breadcrumbs/BrandBreadcrumbs.vue";

import type { ProductInterface } from "~/modules/GraphQL/types";
import type { SortingModel } from "~/modules/catalog/category/composables/useFacet/sortingOptions";
import type { Pagination } from "~/composables/types";
import type { Product } from "~/modules/catalog/product/types";
import SfImage from "~/modules/catalog/product/components/SfImage.vue";
import Preload from "~/components/Preload.vue";

export default defineComponent({
  name: "CategoryPage",
  components: {
    CategoryPagination,
    CategoryEmptyResults: () =>
      import("~/modules/catalog/category/components/CategoryEmptyResults.vue"),
    CategoryFilters: () =>
      import(
        "~/modules/catalog/category/components/filters/CategoryFilters.vue"
      ),
    BreadcrumbFilters: () =>
      import(
        "~/modules/catalog/category/components/filters/BreadcrumbFilters.vue"
      ),
    CmsContent: () =>
      import("~/modules/catalog/category/components/cms/CmsContent.vue"),
    CategoryProductGrid: () =>
      import(
        "~/modules/catalog/category/components/views/CategoryProductGrid.vue"
      ),
    CategoryProductList: () =>
      import(
        "~/modules/catalog/category/components/views/CategoryProductList.vue"
      ),
    CategoryNavbar,
    CategoryBreadcrumbs,
    BrandBreadcrumbs,
    SfSelect,
    LazyHydrate,
    SfHeading,
    SkeletonLoader,
    SfImage,
    Preload,
  },
  transition: "fade",
  props: {
    page_type: {
      type: String,
      default: "category",
    },
    search_term: {
      type: String,
    },
    brand_value: {
      type: String,
    },
    brand_image: {
      type: String,
    },
    brand_description: {
      type: String,
    },
    brand_name: {
      type: String,
    },
    brand_image_social: {
      type: String,
    },
  },
  setup(props) {
    const { routeData } = usePageStore();
    const { getContentData } = useCategoryContent();
    const { loadCategoryMeta } = useCategory();
    const { addTags } = useCache();
    const { config } = useConfig();
    const uiHelpers = useUiHelpers();
    const cmsContent = ref("");
    const categoryImage = ref("");
    const brandImage = ref("");
    const brandDescription = ref("");
    const isShowCms = ref(false);
    const isShowProducts = ref(false);
    const products = ssrRef<ProductInterface[]>([]);
    const sortBy = ref<SortingModel>({ selected: "", options: [] });
    const pagination = ref<Pagination>({});
    const productContainerElement = ref<HTMLElement | null>(null);
    const baseMediaUrl = config.value.secure_base_media_url;
    const baseUrl = config.value.secure_base_url;
    const isLoading = ref(true);
    const brandImageSocial = ref("");

    const {
      toggleFilterSidebar,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
      isFilterSidebarOpen,
    } = useUiState();
    const {
      load: loadWishlist,
      addItem: addItemToWishlistBase,
      isInWishlist,
      removeItem: removeItemFromWishlist,
    } = useWishlist();
    const { result, search, loading } = useFacet();
    const { addItemToCart } = useAddToCart();

    const categoryMeta = ref(null);

    const addItemToWishlist = async (product: Product) => {
      await (isInWishlist({ product })
        ? removeItemFromWishlist({ product })
        : addItemToWishlistBase({ product }));
    };

    const { activeCategory, loadCategoryTree } = useTraverseCategory();
    const activeCategoryName = ref(null);
    const categoryUid = ref(null);
    const pageType = ref(null);
    const searchTerm = ref(null);
    const logo = config.value.header_logo_src;
    const logo_web = baseMediaUrl && logo ? `${baseMediaUrl}logo/${logo}` : "";
    const {
      localePath,
      localeRoute,
      app: { i18n },
    } = useContext();

    const { fetch } = useFetch(async () => {
      const isSearchOrBrandPage =
        props.page_type === "search" || props.page_type === "brand";
      const categoryUidValue = isSearchOrBrandPage
        ? props.search_term
        : routeData?.uid;

      if (props.page_type === "search") {
        categoryUid.value = props.search_term;
        pageType.value = "search";
        const filterParams = uiHelpers.getFacetsFromURL();
        delete filterParams.filters.q;

        const [content] = await Promise.all([
          search({ ...uiHelpers.getFacetsFromURL(), q: categoryUidValue }),
        ]);

        isShowProducts.value = true;
        categoryMeta.value = {
          name: i18n.t("Search results for : ") + props.search_term,
          meta_description: null,
          meta_keywords: props.search_term,
          meta_title: i18n.t("Search results for : ") + props.search_term,
        };
        products.value = facetGetters.getProducts(result.value) ?? [];
        sortBy.value = facetGetters.getSortOptions(result.value);
        pagination.value = facetGetters.getPagination(result.value);
        activeCategoryName.value = computed(() =>
          i18n.t("Search results for ")
        );
        searchTerm.value = props.search_term;
      } else if (props.page_type === "brand") {
        categoryUid.value = props.brand_value;
        pageType.value = "brand";
        const filterParams = uiHelpers.getFacetsFromURL();
        const [content] = await Promise.all([
          search({ ...uiHelpers.getFacetsFromURL(), brand: props.brand_value }),
        ]);

        categoryMeta.value = {
          name: i18n.t("Brand : ") + props.brand_name,
          meta_description: props.brand_description,
          meta_keywords: props.brand_name,
          meta_title: i18n.t("Brand : ") + props.brand_name,
          image: {
            url: props.brand_image_social,
          },
        };
        isShowProducts.value = true;
        products.value = facetGetters.getProducts(result.value) ?? [];
        sortBy.value = facetGetters.getSortOptions(result.value);
        pagination.value = facetGetters.getPagination(result.value);
        activeCategoryName.value = computed(() => props.brand_name);
      } else {
        activeCategoryName.value = computed(
          () => activeCategory.value?.name ?? ""
        );
        if (!activeCategory.value) {
          await loadCategoryTree();
        }
        pageType.value = "category";
        categoryUid.value = routeData?.uid;

        const [content, categoryMetaData] = await Promise.all([
          getContentData(categoryUid.value as string),
          loadCategoryMeta({ category_uid: categoryUid.value }),
          search({
            ...uiHelpers.getFacetsFromURL(),
            category_uid: categoryUid,
          }),
        ]);

        brandDescription.value = content?.description;
        categoryMeta.value = categoryMetaData;
        cmsContent.value = content?.cmsBlock?.content ?? "";
        isShowCms.value = content.isShowCms;
        isShowProducts.value = content.isShowProducts;
        categoryImage.value = content?.image?.replace(
          baseUrl + "media/",
          baseMediaUrl
        );

        products.value = facetGetters.getProducts(result.value) ?? [];
        sortBy.value = facetGetters.getSortOptions(result.value);
        pagination.value = facetGetters.getPagination(result.value);
        //  console.log(products.value, "products.value");
      }
      const tags = [{ prefix: CacheTagPrefix.View, value: categoryUidValue }];
      const productTags = products.value.map((product) => ({
        prefix: CacheTagPrefix.Product,
        value: product.uid,
      }));

      addTags([...tags, ...productTags]);
    });

    const isPriceLoaded = ref(false);

    onMounted(async () => {
      isLoading.value = false;
      document.body.setAttribute("class", "catalog-view-page");
      loadWishlist();
      const { getPricesBySku } = usePrice();
      if (products.value.length > 0) {
        const skus = products.value.map((item) => item.sku);
        const priceData = await getPricesBySku(
          skus,
          pagination.value.itemsPerPage
        );
        products.value = products.value.map((product) => {
          const priceRange = priceData.items.find(
            (item) => item.sku === product.sku
          )?.price_range;

          if (priceRange) {
            return {
              ...product,
              price_range: priceRange,
            };
          }

          return { ...product };
        });
      }

      isPriceLoaded.value = true;
    });

    const goToPage = (page: number) => {
      uiHelpers.changePage(page, false);
      fetch();
    };

    const doChangeItemsPerPage = (itemsPerPage: number) => {
      uiHelpers.changeItemsPerPage(itemsPerPage, false);
      goToPage(0);
    };

    const onReloadProducts = () => {
      goToPage(0);
      productContainerElement.value.scrollIntoView();
    };

    brandImage.value = props?.brand_image;
    brandImageSocial.value = props?.brand_image_social;
    brandDescription.value = props?.brand_description;

    return {
      loading,
      isLoading,
      isPriceLoaded,
      ...uiHelpers,
      toggleFilterSidebar,
      isCategoryGridView,
      changeToCategoryListView,
      changeToCategoryGridView,
      isFilterSidebarOpen,
      addItemToCart,
      addItemToWishlist,
      pagination,
      products,
      sortBy,
      isShowCms,
      isShowProducts,
      cmsContent,
      activeCategoryName,
      routeData,
      doChangeItemsPerPage,
      productContainerElement,
      categoryMeta,
      onReloadProducts,
      goToPage,
      categoryUid,
      pageType,
      searchTerm,
      brandImage,
      brandDescription,
      categoryImage,
      logo_web,
      brandImageSocial
    };
  },
  head() {
    //@ts-ignore
    if (this.categoryMeta && typeof this.categoryMeta.image == "undefined") {
      Object.assign(this.categoryMeta, {
        image: { url: this.logo_web },
      });
    }

    return getMetaInfo(this.categoryMeta);
  },
});
