var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationProvider',{attrs:{"rules":{ required: (typeof _vm.field.required === 'undefined' ? false : true) }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('custom-form-field'),expression:"'custom-form-field'"}],class:'form__element',attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":_vm.identifier + '-' + _vm.formId + '-' + _vm.field.name,"type":_vm.field.type === 'textinput' ? 'text' : _vm.field.type,"label":_vm.field.label,"placeholder":_vm.field.placeholder},on:{"input":function ($event) { return _vm.setCustomFormValue(
            _vm.identifier,
            String(_vm.formId), 
            _vm.field.name,
            $event
          ); }},model:{value:(_vm.custom_field),callback:function ($$v) {_vm.custom_field=$$v},expression:"custom_field"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }