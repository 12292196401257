









import {
  defineComponent,
  useContext,
  ref,
  useFetch
} from '@nuxtjs/composition-api';
import { usePageStore } from '~/stores/page';
import CATEGORY from '~/modules/catalog/pages/category.vue';
import CMS_PAGE from '~/pages/Cms.vue';
import PRODUCT from '~/modules/catalog/pages/product.vue';
import urlResolver from '~/helpers/url-resolver';


export default defineComponent({
  name: 'PageResolver',
  components: {
    CATEGORY,
    CMS_PAGE,
    PRODUCT
  },
  setup() {
    const type = ref();
    const { routeData } = usePageStore();

    const { app } = useContext();
    const { fetch, fetchState } = useFetch(async () => {
      const reFetchPageData = await urlResolver(app.context);
      type.value = reFetchPageData.type;
    });
    fetch();

    return {
      type,
      routeData
    };
  },
});
