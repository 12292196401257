

















import {
  ref,
  computed,
  useContext,
  useRoute,
  defineComponent,
  useFetch,
  onMounted,
  useRouter,
} from "@nuxtjs/composition-api";
import { merge } from "lodash-es";
import { useCache, CacheTagPrefix } from "@vue-storefront/cache";
import { SfBreadcrumbs, SfLoader } from "@storefront-ui/vue";
import { getBreadcrumbs } from "~/modules/catalog/product/getters/productGetters";
import { useProduct } from "~/modules/catalog/product/composables/useProduct";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import { usePageStore } from "~/stores/page";
import { ProductTypeEnum } from "~/modules/catalog/product/enums/ProductTypeEnum";
import { useWishlist, useApi, useConfig } from "~/composables";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import FacebookChatButtonMobile from "~/components/FacebookChatButtonMobile.vue";
import type { Product } from "~/modules/catalog/product/types";
import type { ProductDetailsQuery } from "~/modules/GraphQL/types";
import ProductSkeleton from "~/modules/catalog/product/components/ProductSkeleton.vue";
import getProductPriceBySkuGql from "~/modules/catalog/product/queries/getProductPriceBySku.gql";
export default defineComponent({
  name: "ProductPage",
  components: {
    ProductSkeleton,
    SimpleProduct: () =>
      import(
        "~/modules/catalog/product/components/product-types/simple/SimpleProduct.vue"
      ),
    BundleProduct: () =>
      import(
        "~/modules/catalog/product/components/product-types/bundle/BundleProduct.vue"
      ),
    ConfigurableProduct: () =>
      import(
        "~/modules/catalog/product/components/product-types/configurable/ConfigurableProduct.vue"
      ),
    GroupedProduct: () =>
      import(
        "~/modules/catalog/product/components/product-types/grouped/GroupedProduct.vue"
      ),
    InstagramFeed: () => import("~/components/InstagramFeed.vue"),
    MobileStoreBanner: () => import("~/components/MobileStoreBanner.vue"),
    RelatedProducts: () =>
      import("~/modules/catalog/product/components/RelatedProducts.vue"),
    UpsellProducts: () =>
      import("~/modules/catalog/product/components/UpsellProducts.vue"),
    LoadWhenVisible,
    SfBreadcrumbs,
    SfLoader,
    FacebookChatButtonMobile,
  },
  transition: "fade",
  setup() {
    const { routeData } = usePageStore();
    const { query } = useApi();
    const product = ref<Product | null>(null);
    const { addTags } = useCache();
    const {
      localePath,
      app: { i18n },
    } = useContext();
    const categoryMeta = ref(null);
    const route = useRoute();
    const { getProductDetails, loading } = useProduct();
    const { error: nuxtError } = useContext();
    const { load: loadWishlist } = useWishlist();
    const router = useRouter();
    const { config } = useConfig();
    const baseMediaUrl = config.value.secure_base_media_url;
    const logo = config.value.header_logo_src;
    const logo_web = baseMediaUrl && logo ? `${baseMediaUrl}logo/${logo}` : '';
    const breadcrumbs = computed({
      get() {
        const productCategories = product.value?.categories ?? [];
        return [
          { text: i18n.t("Home") as string, link: localePath("/") },
          ...getBreadcrumbs(
            product.value,
            Array.isArray(productCategories)
              ? [...productCategories].pop()
              : null
          ).map((breadcrumb) => ({
            ...breadcrumb,
            link: localePath(breadcrumb.link),
          })),
        ];
      },
      set(val) {
        // your setter
      },
    });

    const getBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: routeData.sku,
        },
      },
      configurations: Object.entries(route.value.query)
        .filter((config) => config[0] !== "wishlist" && config[0] !== "fbclid")
        .map((config) => config[1]) as string[],
    });

    // eslint-disable-next-line no-underscore-dangle
    const renderer = computed({
      get() {
        return product.value?.__typename ?? ProductTypeEnum.SIMPLE_PRODUCT;
      },
      set(val) {
        // your setter
      },
    });

    const fetchProductBaseData = async (searchQuery = getBaseSearchQuery()) => {
      const result = await getProductDetails({
        ...searchQuery,
      });

      product.value = merge(
        {},
        product.value,
        (result.items[0] as Product) ?? null
      );
    };

    const fetchProductExtendedData = async (
      searchQuery = getBaseSearchQuery()
    ) => {
      const { data } = await query<ProductDetailsQuery>(
        getProductPriceBySkuGql,
        searchQuery
      );
      if (data.products?.items.length === 0) {
        await router.push(localePath("/"));
      }


      product.value = merge(
        {},
        product.value,
        data.products?.items?.[0] as Product
      );
    };

    useFetch(async () => {
      await fetchProductBaseData();

      if (Boolean(product?.value?.sku) === false)
        nuxtError({ statusCode: 404 });

      const tags = [
        {
          prefix: CacheTagPrefix.View,
          value: `product-${routeData.sku}`,
        },
      ];

      const productTags = [
        {
          prefix: CacheTagPrefix.Product,
          value: product.value.uid,
        },
      ];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      addTags([...tags, ...productTags]);
    });

    onMounted(async () => {
      await Promise.all([fetchProductExtendedData(), loadWishlist()]);
    });
    return {
      renderer,
      loading,
      breadcrumbs,
      product,
      path: route.value.path,
      fetchProduct: fetchProductExtendedData,
      logo_web,
      categoryMeta
    };
  },
  head() {
    if (this.product) {
      const product = ref(null);
      product.value = this.product;
      product.value.url = `${process.env.VSF_STORE_URL}` + this.path;

      //@ts-ignore
      if (this.product && typeof this.product.image == "undefined") {
        Object.assign(this.categoryMeta, {
          image: { url: this.logo_web }
        });
      }
      return getMetaInfo(this.product);
    }
  },
});
