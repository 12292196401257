










import { defineComponent } from "@nuxtjs/composition-api";

export default defineComponent({
  name: 'GoogleRecaptcha',
  props: {
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
});
