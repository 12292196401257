var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationProvider',{attrs:{"rules":{ required: (typeof _vm.field.required === 'undefined' ? false : true) }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form__element sf-textarea",attrs:{"type":"text"}},[_c('label',{staticClass:"sf-textarea__label",attrs:{"for":_vm.formId + '-' + _vm.field.name}},[_vm._v(_vm._s(_vm.field.label))]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.custom_field),expression:"custom_field"},{name:"e2e",rawName:"v-e2e",value:('custom-form-field'),expression:"'custom-form-field'"}],class:'form__element',attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":_vm.identifier + '-' + _vm.formId + '-' + _vm.field.name,"id":_vm.formId + '-' + _vm.field.name,"type":"text","label":_vm.field.label,"placeholder":_vm.field.placeholder,"cols":"20","rows":"5","wrap":"soft"},domProps:{"value":(_vm.custom_field)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.custom_field=$event.target.value},function ($event) { return _vm.setCustomFormValue(
              _vm.identifier,
              String(_vm.formId), 
              _vm.field.name,
              $event.target.value
            ); }]}})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }