





















































































import { defineComponent, ref, useContext } from "@nuxtjs/composition-api";
import { ValidationObserver } from "vee-validate";
import type { PropType } from "@nuxtjs/composition-api";
import { 
  SfInput, 
  SfButton, 
  SfCheckbox, 
  SfLoader
} from "@storefront-ui/vue";
import type { CustomForm, CustomFormField } from "~/modules/GraphQL/types";
import CustomFormFieldInput from "~/components/CustomForm/CustomFormFieldInput.vue";
import CustomFormFieldTextArea from "~/components/CustomForm/CustomFormFieldTextArea.vue";
import CustomFormFieldSelect from "~/components/CustomForm/CustomFormFieldSelect.vue";
import CustomFormFieldMultipleSelect from "~/components/CustomForm/CustomFormFieldMultipleSelect.vue";
import CustomFormFieldCheckbox from "~/components/CustomForm/CustomFormFieldCheckbox.vue";
import CustomFormFieldRadio from "~/components/CustomForm/CustomFormFieldRadio.vue";
import GoogleRecaptcha from "~/components/GoogleRecaptcha.vue";
import SfAlert from "./SfAlert.vue";
import { useUiState, useCustomForm } from '~/composables';
import { useConfigStore } from '~/stores/config';

export default defineComponent({
  name: 'CustomFormComp',
  components: {
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    SfAlert,
    ValidationObserver,
    CustomFormFieldInput,
    CustomFormFieldTextArea,
    CustomFormFieldSelect,
    CustomFormFieldMultipleSelect,
    CustomFormFieldCheckbox,
    CustomFormFieldRadio,
    GoogleRecaptcha,
  },
  props: {
    custom_form: {
      type: Object as PropType<CustomForm>,
      default: null
    },
    loading: {
      type: Boolean,
      default: false,
    },
    identifier: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { customFormValue, setCustomFormValue } = useUiState();
    const { postCustomForm, error } = useCustomForm();
    const configStore = useConfigStore();

    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref<boolean>(
      typeof $recaptcha !== "undefined" && Boolean($config.isRecaptcha)
    );

    const getRecaptchaInfo = async (
      isRecaptchaOn: boolean
    ): Promise<{ token: string | null; cleanup: () => void }> => {
      if (isRecaptchaOn) {
        $recaptcha.init();
        return {
          token: await $recaptcha.getResponse(),
          cleanup: () => {
            $recaptcha.reset();
          },
        };
      }
      return { token: null, cleanup: () => {} };
    };
    const form_json = ref([]);
    const custom_field = ref('');
    const success = ref(false);
    const errors = ref(false);
    const loadings = ref(false);
    form_json.value = JSON.parse(props.custom_form.form_json);
    const submitForm = async (event) => {
      const { token, cleanup } = await getRecaptchaInfo(
        isRecaptchaEnabled.value
      );
      loadings.value = true;
      errors.value = false;
      const currentDate = new Date();
      const form_submit = {};
      form_json.value[0].map((value: CustomFormField) => {
        const _key = `${props.identifier}-${props.custom_form.form_id}-${value.name}`;
        form_submit[value.name] = {
          value: typeof customFormValue.value[_key] !== 'undefined' ? customFormValue.value[_key] : '',
          label: value.label,
          type: value.type
        };
      });
      const response_json = JSON.stringify(form_submit);
      await postCustomForm({ 
        submitAnswer: {
          answer: {
            form_id: props.custom_form.form_id,
            store_id: configStore.$state.storeConfig.id,
            created_at: currentDate,
            response_json: response_json,
            admin_response_email: props.custom_form.email_field !== '' ? form_submit[props.custom_form.email_field].value : '',
          },
          google_recaptcha_token: token
        }
      });
      cleanup();
      if (!error.value.postCustomForm) {
        loadings.value = false;
        success.value = true;
        event.target.reset();
        setCustomFormValue(
          props.identifier,
          String(props.custom_form.form_id),
          '',
          ''
        )
      } else {
        loadings.value = false;
        errors.value = true;
      }
    }
    return {
      custom_field,
      form_json,
      submitForm,
      success,
      errors,
      loadings,
      isRecaptchaEnabled,
    };
  },
});
