































import { SfLoader, SfHeading } from "@storefront-ui/vue";
import {
  defineComponent,
  ref,
  useFetch,
  useContext,
  onMounted,
} from "@nuxtjs/composition-api";
import { useCache, CacheTagPrefix } from "@vue-storefront/cache";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import { useContent, useSidebarMenuCms, useCustomForm, useConfig } from "~/composables";
import type { CmsPage, SidebarMenuCms, CustomForm } from "~/modules/GraphQL/types";
import { usePageStore } from "~/stores/page";
import HTMLContent from "~/components/HTMLContent.vue";
import IconArrowRt from "~/components/General/IconArrowRt.vue";
import Preload from "~/components/Preload.vue";
import { Breadcrumb } from "~/modules/catalog/types";
import { SfBreadcrumbs } from "@storefront-ui/vue";
import CustomFormComp from "~/components/CustomForm.vue";
import { useConfigStore } from '~/stores/config';

export default defineComponent({
  name: "CmsPage",
  components: {
    HTMLContent,
    SfLoader,
    SfHeading,
    IconArrowRt,
    Preload,
    SfBreadcrumbs,
    CustomFormComp,
  },
  setup() {
    const { routeData } = usePageStore();
    const { addTags } = useCache();
    const { error: nuxtError } = useContext();
    const { loadPage, loading, error } = useContent();
    const { getByIdentifier } = useSidebarMenuCms();
    const { getCustomFormById } = useCustomForm();
    const configStore = useConfigStore();
    const page_content = ref(null);
    const locations = ref(null);
    const google_map_zoom = configStore.$state.storeConfig.google_map_zoom;

    const page = ref<CmsPage | null>(null);
    const menus = ref<SidebarMenuCms[] | null>(null);
    const custom_form = ref<CustomForm | null>(null);
    const form_position = ref('col-lg-12 bottom-content');

    const menuShow = ref();
    const isLoaded = ref(false);
    const isBreadcrumbLoaded = ref(false);
    const {
      localePath,
      app: { i18n },
    } = useContext();

    const breadcrumbs = ref<Breadcrumb[]>([
      {
        text: i18n.t("Home") as string,
        link: localePath("/"),
      },
    ]);

    const { config } = useConfig();
    const logo = config.value.header_logo_src;
    const baseMediaUrl = config.value.secure_base_media_url;
    const logo_web = baseMediaUrl && logo ? `${baseMediaUrl}logo/${logo}` : '';

    useFetch(async () => {
      page.value = await loadPage({ identifier: routeData.identifier });
      breadcrumbs.value.push({
        text: i18n.t(page.value.title) as string,
        link: localePath(routeData.identifier),
      });
      isBreadcrumbLoaded.value = true;
      const locale_code = i18n.locale;
      const _menus = await getByIdentifier({ identifier: `left-menu-${locale_code}` });
      isLoaded.value = true;
      menus.value = _menus;
      _menus.map((value: any) => {
        if (value.content === page.value.identifier) {
          menuShow.value = value.parent_id;
        }
      });

      if (page.value.custom_form_id !== null) {
        custom_form.value = await getCustomFormById({ formId: page.value.custom_form_id });
        if (page.value.custom_form_position === 'top') {
          form_position.value = 'col-lg-12 top-content';
        } else if (page.value.custom_form_position === 'left') {
          form_position.value = 'col-lg-6 left-content';
        } else if (page.value.custom_form_position === 'right') {
          form_position.value = 'col-lg-6 right-content';
        }
      }

      var regex = new RegExp('data-locations="([^"]*)"');
      if (regex.test(page.value.content)) {
        let content = regex.exec(page.value.content);
        locations.value = JSON.parse(decodeHtml(content[1]));
      }

      if (error?.value?.page || !page.value) nuxtError({ statusCode: 404 });

      addTags([{ prefix: CacheTagPrefix.View, value: routeData.identifier }]);
    });

    onMounted(async () => {
      document.body.setAttribute("class", "cms-page");
    });

    const decodeHtml = (str) => {
      return str.replaceAll('&quot;&quot;', '\"\"').replaceAll('&quot;', '\"');
    }

    const toggleMenu = (index: number) => {
      if (index === menuShow.value) {
        menuShow.value = 0;
      } else {
        menuShow.value = index;
      }
    };

    return {
      page,
      loading,
      menus,
      toggleMenu,
      menuShow,
      isLoaded,
      breadcrumbs,
      isBreadcrumbLoaded,
      custom_form,
      form_position,
      routeData,
      page_content,
      locations,
      google_map_zoom,
      logo_web
    };
  },
  head() {
    //@ts-ignore
    if (this.page && typeof this.page.image === "undefined") {
      Object.assign(this.page, {
        image: { url: this.logo_web }
      });
    }
    return getMetaInfo(this.page);
  },
});
