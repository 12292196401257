




import {
  defineComponent,
  useContext,
  useFetch,
  ref,
} from "@nuxtjs/composition-api";
import { SfBreadcrumbs } from "@storefront-ui/vue";
import { useUiHelpers } from "~/composables";
import { useTraverseCategory } from "~/modules/catalog/category/helpers/useTraverseCategory";
import { Breadcrumb } from "~/modules/catalog/types";

export default defineComponent({
  components: { SfBreadcrumbs },
  setup() {
    const { getCatLink } = useUiHelpers();
    const {
      localePath,
      app: { i18n },
    } = useContext();

    const { categoryAncestors, isCategoryTreeLoaded, loadCategoryTree } =
      useTraverseCategory();
    const breadcrumbs = ref<Breadcrumb[]>([]);

    useFetch(async () => {
      if (!isCategoryTreeLoaded.value) {
        await loadCategoryTree();
      }
      breadcrumbs.value = [
        { text: i18n.t("Home") as string, link: localePath("/") },
        ...categoryAncestors.value.slice(0, categoryAncestors.value.length).map((category) => ({
          text: category.name,
          link: localePath(getCatLink(category)),
        })),
      ];
    });

    return {
      breadcrumbs,
    };
  },
});
