




import {
  defineComponent,
  useContext,
  useFetch,
  ref,
} from "@nuxtjs/composition-api";
import { SfBreadcrumbs } from "@storefront-ui/vue";
import { Breadcrumb } from "~/modules/catalog/types";

export default defineComponent({
  components: { SfBreadcrumbs },
  props: {
    brand_name: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const {
      localePath,
      app: { i18n },
    } = useContext();

    const breadcrumbs = ref<Breadcrumb[]>([]);

    useFetch(async () => {
      breadcrumbs.value = [
        { text: i18n.t("Home") as string, link: localePath("/") },
        { text: i18n.t("Shop by Brand") as string, link: localePath("/shop-by-brand.html") },
        { text: props.brand_name as string, link: localePath("/brand/" + props.brand_name.toLowerCase()) },
      ];
    });

    return {
      breadcrumbs,
    };
  },
});
