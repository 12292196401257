var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationProvider',{attrs:{"rules":{ required: (typeof _vm.field.required === 'undefined' ? false : true) }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"sf-select sf-select--underlined product__select-size is-selected"},[_c('label',{staticClass:"sf-select__label will-change",staticStyle:{"will-change":"font-size"},attrs:{"for":"DropDown"}},[_vm._v("\n        "+_vm._s(_vm.field.label)+"\n      ")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.custom_field),expression:"custom_field"},{name:"e2e",rawName:"v-e2e",value:('custom-form-field'),expression:"'custom-form-field'"}],staticClass:"sf-select__dropdown",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":_vm.identifier + '-' + _vm.formId + '-' + _vm.field.name,"multiple":true},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.custom_field=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function () { return _vm.setCustomFormValue(
              _vm.identifier,
              String(_vm.formId), 
              _vm.field.name,
              _vm.custom_field
            ); }]}},_vm._l((_vm.field.values),function(value,key){return _c('option',{key:key,domProps:{"value":value.value}},[_vm._v("\n          "+_vm._s(value.label)+"\n        ")])}),0)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }